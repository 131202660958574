$msv-checkout-guest-profile-input-height: 32px;
$msv-checkout-guest-profile-input-label-width: 100%;
$msv-checkout-guest-profile-input-text-max-width: 610px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;
$msv-checkout-guest-profile-textbox-height: 48px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.04rem 0;
$msv-alert-icon-margin: 8px;
$msv-contact-email-textbox-height: 52px;
$msv-alert-error-content-width: 4px;
$msv-error-icon-font-size: 20px;

:root {
    --msv-contact-form-error-border-color: #{$msv-red};
    --msv-contact-form-error-font-color: #{$msv-red};
}

.ms-checkout-guest-profile {
    &__add-contact-info {
        width: 100%;
    }

    &__input {
        display: flex;
        flex-wrap: wrap;
    }

    &__selected-email {
        @include font-body-regular-m();
    }

    &__input-text {
        flex-grow: 1;
        max-width: $msv-checkout-guest-profile-input-text-max-width;

        @include form-input-box();
    }

    &__input-label {
        @include form-input-label();
        width: $msv-checkout-guest-profile-input-label-width;
    }

    &__input-error {
        display: flex;
        align-items: flex-end;
        width: 100%;
        order: 3;

        @include alert;
    }

    &__add-ponumber-info {
        border-top: 1px solid var(--msv-checkout-border);
        padding-top: 32px;
        margin-top: 32px;

        & label{
            float: left;
            width: 120px;
            margin-top: 10px;
        }
    }
}
