$content-block-min-height: 150px;
$hero-body-font-size: 16px;
$hero-body-line-height: 21px;
$hero-body-font-weight: normal;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 4px;
$hero-content-button-margin-top: 32px;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-font-weight: normal;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-secondary-padding: 12px 20px;
$additional-content-line-height: 80px;
$msv-mobile-title-font-size-xl: 40px;
$msv-tablet-title-font-size-xl: 60px;
$msv-title-line-height: 50px;
$msv-tablet-title-line-height-xl: 72px;
$content-block-details-padding-desktop: 120px 80px 60px 80px;
$content-block-details-padding-tablet: 40px 24px 28px 24px;
$content-block-details-padding-mobile: 40px 16px 28px 16px;
$additional-content-cta-margin-mobile: -117px;
$additional-content-cta-margin-tablet: -137px;
$msv-mobile-title-font-size-s: 24px;
$msv-mobile-title-line-height-m: 40px;
$msv-tablet-title-line-height-m: 40px;
$additional-content-text-font-size: 36px;
$b2b-hero-body-right-margin: 40px;
$b2b-hero-content-text-margin-top: 28px;
$b2b-hero-content-button-margin-top: 28px;
$b2b-hero-link-to-link-padding: 20px;
$b2b-hero-links-secondary-margin-left: 20px;
$b2b-hero-links-mobile-secondary-margin-top: 14px;
$b2b-content-block-details-margin-left: 120px;
$b2b-content-block-details-margin-top: 79px;
$b2b-content-block-paragraph-padding-right: 5px;
$additional-content-link-padding: 4px;
$msc-invoices-list-table-header-padding-right: 20px;
$msc-invoices-list-table-header-last-child-padding-right: 0;
$msc-invoices-list-table-row-padding-right: 20px;
$msc-invoices-list-table-row-nth-child-margin-right: 10px;
$msc-invoices-list-table-row-nth-child-width-mobile: 140px;
$msc-invoices-list-table-row-nth-child-user-width: 86px;
$msc-invoices-list-table-row-last-child-width-mobile: 48px;
$msc-invoices-list-table-row-first-child-width-mobile: 70px;
$msc-invoices-list-table-header-row-height: 43px;
$msc-invoices-list-table-body-row-height: 82px;
 :root {
    // heading
    --msv-content-block-heading-font-size: #{$hero-heading-font-size};
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);
    // additional content heading
    --msv-additional-content-text-font-size: #{$additional-content-text-font-size};

    // Text theme
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);
    --msv-content-block-textheme-light: #{$msv-white};
    --msv-content-block-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

:root {
    --msv-tab-heading-font-size: var(--msv-body-font-size-xl);
    --msv-tab-heading-font-color: var(--msv-font-primary-color);
    --msv-tab-font-size: var(--msv-body-font-size-m);
    --msv-tab-font-color: var(--msv-font-primary-color);
}

@mixin overlay-text-on-image {
    .ms-content-block__details {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
    }
}

.ms-content-block {
    min-height: $content-block-min-height;

    &[data-m-layout="full-width"] {
        position: relative;

        .ms-content-block__image,
        .ms-content-block__image img {
            width: $msv-width-full;
        }

        .ms-content-block__image svg {
            width: $msv-width-full;
        }

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        .ms-content-block__title {
            @include font-callout-bold-l();
            color: $msv-white;
            letter-spacing: 0.01em;
            width: calc(8 * #{$msv-column-width-s});

            @media (max-width: $msv-breakpoint-m) {
                @include font-callout-bold-s();
                color: $msv-white;
                width: calc(4 * #{$msv-column-width-l});
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-xl) {
                @include font-callout-bold-m();
                color: $msv-white;
                width: calc(8 * #{$msv-column-width-m});
            }
        }

        .ms-content-block__text {
            @include font-body-regular-m();
            color: $msv-white;
            width: calc(6 * #{$msv-column-width-s});

            @media (max-width: $msv-breakpoint-m) {
                width: calc(4 * #{$msv-column-width-l});
                color: $msv-white;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: calc(8 * #{$msv-column-width-m});
                color: $msv-white;
            }
        }

        &.texttheme__light .ms-content-block__title,
        &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__title,
        &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);
        }

        /* body should have top margin only when there's something above it */
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        * + .ms-content-block__text {
            margin-top: $hero-content-text-margin-top;
        }

        .ms-content-block__cta {
            border-radius: 2px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            z-index: 99;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $hero-content-button-margin-top;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__details {
            margin: 9% 60px 60px 80px;
            display: flex;
            flex-direction: column;
            align-content: center;

            .msc-cta__primary {
                @include secondary-button-light();
            }

            .msc-cta__secondary {
                @include secondary-button-light();
                margin: 12px 0;
            }

            @media (max-width: $msv-breakpoint-m) {
                padding: $content-block-details-padding-mobile;
                position: static;
                background: $msv-gray-20;
                margin: 0;

                .msc-cta__primary {
                    @include secondary-button-dark();
                }

                .msc-cta__secondary {
                    @include secondary-button-dark();
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                padding: $content-block-details-padding-tablet;
                position: static;
                background: $msv-gray-20;
                margin: 0;

                .msc-cta__primary {
                    @include secondary-button-dark();
                }

                .msc-cta__secondary {
                    @include secondary-button-dark();
                }
            }

            &__additional-content {
                display: grid;
                font-style: normal;
                font-weight: $msv-font-weight-normal;
                flex-direction: column;
                color: $msv-white;
                margin-left: auto;
                width: $msv-width-full;
                margin-right: 60px;
                margin-bottom: 80px;
                position: absolute;
                justify-content: end;
                bottom: 80px;

                &__text {
                    @include msv-beth-ellen(
                        $font-weight: $msv-font-weight-normal,
                        $font-style: $msv-font-style-normal,
                        $font-size: var(--msv-additional-content-text-font-size),
                        $line-height: $additional-content-line-height
                    );
                    color: $msv-white;
                    text-align: center;
                }

                &__container {
                    display: flex;
                    margin-top: 8px;
                }

                &__paragraph {
                    padding-right: 20px;
                    line-height: $msv-line-height-m;
                }

                &-cta {
                    @include link-regular-m();
                    padding-left: 20px;
                    border-left: 1px solid $msv-white;

                    &-links {
                        &:nth-child(1) {
                            text-decoration: underline;
                            color: $msv-white;
                        }

                        &:nth-child(2) {
                            padding-left: 20px;
                            padding-right: 20px;
                            text-decoration-line: underline;
                            border-left: 1px solid $msv-white;
                            color: $msv-white;
                        }
                    }
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    padding: 0;
                    position: absolute;
                    width: calc(4 * #{$msv-column-width-l});
                    margin: 0 auto;
                    right: 0;
                    align-items: flex-end;
                    margin-top: $additional-content-cta-margin-mobile;
                    margin-right: 16px;
                    top: auto;
                    bottom: auto;

                    &__text {
                        @include msv-beth-ellen(
                            $font-weight: $msv-font-weight-normal,
                            $font-style: $msv-font-style-normal,
                            $font-size: $msv-mobile-title-font-size-s,
                            $line-height: $msv-mobile-title-line-height-m
                        );
                        text-align: center;
                    }

                    &-cta {
                        text-align: left;

                        &-links {
                            &:nth-child(2) {
                                padding-left: 4px;
                                padding-right: 4px;
                            }
                        }
                    }
                }

                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                    padding: 0;
                    position: absolute;
                    width: calc(4 * #{$msv-column-width-m});
                    margin: 0 auto;
                    right: 0;
                    align-items: flex-end;
                    margin-top: $additional-content-cta-margin-tablet;
                    margin-right: 40px;
                    top: auto;
                    bottom: auto;

                    &__text {
                        @include msv-beth-ellen(
                            $font-weight: $msv-font-weight-normal,
                            $font-style: $msv-font-style-normal,
                            $font-size: $msv-font-size-l,
                            $line-height: $msv-tablet-title-line-height-m
                        );
                    }

                    &-cta {
                        text-align: left;

                        &-links {
                            &:nth-child(2) {
                                padding-left: 4px;
                                padding-right: 4px;
                            }
                        }
                    }
                }
            }
        }

        &.texttheme__light .ms-content-block__details .msc-cta__primary, &.texttheme__light .ms-content-block__details .msc-cta__secondary {
            @include secondary-button-light();
        }

        &.texttheme__dark .ms-content-block__details .msc-cta__primary, &.texttheme__dark .ms-content-block__details .msc-cta__secondary {
            @include secondary-button-dark();
        }

        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content__text,
        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content__paragraph,
        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta-links:nth-child(1) {
            color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content__text,
        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content__paragraph,
        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta-links:nth-child(1) {
            color: var(--msv-content-block-textheme-dark);
        }

        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta {
            border-color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta {
            border-color: var(--msv-content-block-textheme-dark);
        }

        /**
        * Text over image by default only in modern browsers.
        * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
        */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }
        }

        /**
        * When the image is tall enough for IE 11 to display text on top of it.
        */

        @media screen and (min-width: $msv-breakpoint-l) {
            @include overlay-text-on-image;
        }
    }

    &[data-m-layout="full-width-b2b"] {
        position: relative;

        .ms-content-block__image,
        .ms-content-block__image img {
            width: $msv-width-full;
        }

        .ms-content-block__image svg {
            width: $msv-width-full;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__title {
            @include font-heading-h2-l();
            color: $msv-white;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            width: calc(8 * #{$msv-column-width-s});

            @media (max-width: $msv-breakpoint-m) {
                @include font-heading-h2-s();
                width: calc(4 * #{$msv-column-width-l});
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                @include font-heading-h2-m();
                width: calc(8 * #{$msv-column-width-m});
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__text {
            @include font-body-regular-m();
            color: $msv-white;
            width: calc(6 * #{$msv-column-width-s});

            @media (max-width: $msv-breakpoint-m) {
                width: calc(4 * #{$msv-column-width-l});
                color: $msv-white;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: calc(8 * #{$msv-column-width-m});
                color: $msv-white;
            }
        }

        &.texttheme__light .ms-content-block__title,
        &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__title,
        &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);
        }

        /* body should have top margin only when there's something above it */
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        * + .ms-content-block__text {
            margin-top: $b2b-hero-content-text-margin-top;
        }

        .ms-content-block__cta {
            border-radius: 2px;
            display: flex;
            justify-content: center;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $b2b-hero-content-button-margin-top;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__details {
            display: flex;
            flex-direction: column;
            align-content: center;
            margin-top: $b2b-content-block-details-margin-top;
            margin-left: $b2b-content-block-details-margin-left;

            @media (max-width: $msv-breakpoint-m) {
                padding: $content-block-details-padding-mobile;
                position: static;
                background: $msv-gray-20;
                margin: 0;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                padding: $content-block-details-padding-tablet;
                position: static;
                background: $msv-gray-20;
                margin: 0;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-cta__secondary {
                @include secondary-button-dark();
                margin-left: $b2b-hero-links-secondary-margin-left;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-cta__primary {
                @include primary-button-dark();
            }

            &__additional-content {
                font-style: normal;
                font-weight: $msv-font-weight-normal;
                color: $msv-white;
                margin-left: auto;
                width: $msv-width-full;
                margin-top: $b2b-hero-content-text-margin-top;

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                &__text {
                    @include msv-beth-ellen(
                        $font-weight: $msv-font-weight-normal,
                        $font-style: $msv-font-style-normal,
                        $font-size: var(--msv-additional-content-text-font-size),
                        $line-height: $additional-content-line-height
                    );
                    color: $msv-white;
                    text-align: center;
                }

                &__container {
                    display: flex;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                &__paragraph {
                    padding-right: $b2b-content-block-paragraph-padding-right;
                    line-height: $msv-line-height-m;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                &-cta {
                    @include link-regular-m();

                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &-links {
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &:nth-child(1) {
                            text-decoration: underline;
                            color: $msv-white;
                        }

                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &:nth-child(2) {
                            padding-left: $b2b-hero-link-to-link-padding;
                            padding-right: $b2b-hero-link-to-link-padding;
                            text-decoration-line: underline;
                            border-left: 1px solid $msv-white;
                            color: $msv-white;
                        }
                    }
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    padding: 0;
                    position: absolute;
                    width: calc(4 * #{$msv-column-width-l});
                    margin: 0 auto;
                    right: 0;
                    align-items: flex-end;
                    margin-top: $additional-content-cta-margin-mobile;
                    margin-right: $hero-link-to-link-spacing;
                    top: auto;
                    bottom: auto;

                    &__text {
                        @include msv-beth-ellen(
                            $font-weight: $msv-font-weight-normal,
                            $font-style: $msv-font-style-normal,
                            $font-size: $msv-mobile-title-font-size-s,
                            $line-height: $msv-mobile-title-line-height-m
                        );
                        text-align: center;
                    }

                    &-cta {
                        text-align: left;

                        &-links {
                            &:nth-child(2) {
                                padding-left: $additional-content-link-padding;
                                padding-right: $additional-content-link-padding;
                            }
                        }
                    }
                }

                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    padding: 0;
                    position: absolute;
                    width: calc(4 * #{$msv-column-width-m});
                    margin: 0 auto;
                    right: 0;
                    align-items: flex-end;
                    margin-top: $additional-content-cta-margin-tablet;
                    margin-right: $b2b-hero-body-right-margin;
                    top: auto;
                    bottom: auto;

                    &__text {
                        @include msv-beth-ellen(
                            $font-weight: $msv-font-weight-normal,
                            $font-style: $msv-font-style-normal,
                            $font-size: $msv-font-size-l,
                            $line-height: $msv-tablet-title-line-height-m
                        );
                    }

                    &-cta {
                        text-align: left;

                        &-links {
                            &:nth-child(2) {
                                padding-left: $additional-content-link-padding;
                                padding-right: $additional-content-link-padding;
                            }
                        }
                    }
                }
            }
        }

        &.texttheme__light .ms-content-block__details .msc-cta__primary {
            @include primary-button-dark();
        }

        &.texttheme__dark .ms-content-block__details .msc-cta__primary {
            @include primary-button-light();
        }

        &.texttheme__light .ms-content-block__details .msc-cta__secondary {
            @include secondary-button-dark();
        }

        &.texttheme__dark .ms-content-block__details .msc-cta__secondary {
            @include secondary-button-light();
        }

        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content__text,
        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content__paragraph,
        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta-links:nth-child(1) {
            color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content__text,
        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content__paragraph,
        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta-links:nth-child(1) {
            color: var(--msv-content-block-textheme-dark);
        }

        &.texttheme__light .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta {
            border-color: var(--msv-content-block-textheme-light);
        }

        &.texttheme__dark .ms-content-block__details__additional-content .ms-content-block__details__additional-content-cta {
            border-color: var(--msv-content-block-textheme-dark);
        }

        /**
        * Text over image by default only in modern browsers.
        * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
        */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }
        }

        /**
        * When the image is tall enough for IE 11 to display text on top of it.
        */

        @media screen and (min-width: $msv-breakpoint-l) {
            @include overlay-text-on-image;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-l) {
    .ms-content-block {
        &[data-m-layout="full-width"] {
            &.texttheme__dark .ms-content-block__title,
            &.texttheme__dark .ms-content-block__text {
                color: $msv-gray-60;
            }

            &.texttheme__light .ms-content-block__title,
            &.texttheme__light .ms-content-block__text {
                color: var(--msv-content-block-textheme-light);
            }
        }

        &[data-m-layout="full-width-b2b"] {
            &.texttheme__dark .ms-content-block__title,
            &.texttheme__dark .ms-content-block__text {
                color: $msv-gray-60;
            }

            &.texttheme__light .ms-content-block__title,
            &.texttheme__light .ms-content-block__text {
                color: var(--msv-content-block-textheme-light);
            }

            & .ms-content-block__cta {
                flex-direction: column;

                .msc-cta__secondary {
                    margin-left: 0;
                    margin-top: $b2b-hero-links-mobile-secondary-margin-top;
                }
            }

            & .ms-content-block__details__additional-content {
                margin-top: $b2b-hero-content-text-margin-top;
                position: relative;
                margin-left: 0;
            }
        }
    }
}



.ms-tab {

    &-header-section {
        margin: 5px 5px 20px 5px;

        &__heading {
            @include font-content(var(--msv-font-weight-heavy), var(--msv-tab-heading-font-size), $msv-line-height-xl);
            color: var(--msv-tab-heading-font-color);
        }
    }

    @media (min-width: $msv-breakpoint-m) {
        &-header-section {
            display: flex;
            margin: 5px 5px 20px 5px;
        }
    }

    &-tabs-section {
        display: flex;
        justify-content: space-between;



        &-li {
            cursor: pointer;
            padding: 10px 15px;
            margin-right: 0px;
            position: relative;
            outline: 0px;
            background: #e7ecef;
            border: 1px solid #d1dae1;
            border-bottom: 0px;

            :hover {
                text-decoration: none;
            }

            .ms-tab-item-header {
                @include font-content(var(--msv-font-weight-normal), var(--msv-tab-font-size), $msv-line-height-s);
                color: var(--msv-tab-font-color);
                font-size: 14px;
            }

            &[aria-selected="true"] {
                background-color: $msv-light-blue;
                color: $msv-white;
                border-color: $msv-light-blue;

                & .ms-tab-item-header {
                    color: $msv-white;
                }
            }
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            ::before {
                background-color: transparent;
                bottom: 0;
                content: "";
                height: 2px;
                left: 8px;
                position: absolute;
                right: 8px;
                transition: background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9);
            }
        }

        @media screen and (max-width: $msv-breakpoint-l) {
            display: inline-block;
            width: 100%;

            &-li {
                margin-top: 5px;
                border: 1px solid $msv-gray-50;
                margin-right: 0px !important;
            }
        }

        li:last-of-type {
            margin-right: 0;
        }
    }

    &-content-section {
        margin-top: 0px;
        padding: 20px;
        border: 1px solid $msv-gray-50;
        margin-bottom: 20px;
    }

    &-item-content__content {
        &[aria-hidden="true"] {
            display: none;
        }
    }
}

.pdp-specifications-content {
    & .ms-tab-tabs-section {
        justify-content: flex-start;

        & li {
            margin-right: 10px;
        }
    }
}

.order-upload-template {
    margin-top: 30px;
    margin-bottom: 30px;
    
}

.ms-order-upload {
    &__btn-checkout {
        @include primary-button-light();
        display: inline-block;
        margin-left: 12px;
        margin-top: 30px;
        margin-bottom: 0;
        float: right;
        
    }

    &__btn-backtoshopping {
        @include secondary-button-light();
        display: inline-block;
        margin-left: 12px;
        margin-top: 30px;
        margin-bottom: 0;
        float: right;
        min-width:130px;
        padding:12px 18px;
    }
}
.order-upload-template {
    table {
        width: 100%;
        border-collapse: collapse;
        border-top: 0.5px solid $msv-gray-50;
        margin-top: 30px;

        tr {
            height: $msc-invoices-list-table-header-row-height;
            border-bottom: 0.5px solid $msv-gray-50;
            text-align:left;

            th {
                padding-right: $msc-invoices-list-table-header-padding-right;

                @include font-body-bold-m();

                &:last-child {
                    padding-right: $msc-invoices-list-table-header-last-child-padding-right;
                }

                &.msc-invoices-list__container__content__table__amountdue {
                    text-align: right;
                }
            }


            td {
                padding-right: $msc-invoices-list-table-row-padding-right;
                @include font-body-regular-s();
            }
        }
    }
}
.ms-order-upload__response{
    margin-top:30px; 
}

.ms-order-upload__loading {
    font-family: "titilliumtext22lregular";
    font-weight: 400;
    font-style: normal;
    font-size: var(--msv-body-font-size-s);
    line-height: var(--msv-body-line-height-s);
    color: $msv-white;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    vertical-align: middle;
    padding-top: 300px;
}
  
.ms-order-upload__loading::before {
    font-family: "Remix Icon";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
    line-height: 1;
    content: "";
    margin-right: 8px;
    border-radius: 50%;
    border-width: 1.5px;
    border-style: solid;
    border-color: #0078d4 #c7e0f4 #c7e0f4;
    border-image: initial;
    animation: spin 1s steps(8) infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
    width: 20px;
    height: 20px;
    font-family: inherit;
  }
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}