$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-order-information-full-width: 100%;
$msv-details-order-information-padding-bottom: 44px;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-qr-code-block-bg-color: $msv-white;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 81px;
$msv-details-order-summary-heading-margin-bottom: 40px;
$msv-details-order-summary-line-spacing: 25px;
$msv-details-order-summary-line-total-amount-padding-bottom: 22px;
$msv-details-order-summary-line-total-amount-margin-bottom: 25px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-top: 48px;
$msv-details-payment-methods-heading-margin-bottom: 23px;
$msv-details-payment-methods-heading-margin-top: 68px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-default-padding: 20px 0;
$msv-details-default-margin: 20px 0;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-main-container-margin: 0;
$msv-details-order-information-padding-top: 54px;
$msv-order-confirmation-groups-padding-top: 48px;
$msv-order-confirmation-groups-padding-bottom: 48px;
$msv-order-confirmation-address-phone-padding-right: 15px;
$msv-order-confirmation-address-phone-margin-top: 15px;
$msv-order-confirmation-payment-method-amount-margin-left: 4px;
$msv-order-confirmation-address-margin-bottom: 0;
$msv-order-confirmation-address-margin-top: 16px;
$msv-order-confirmation-pickup-date-timeslot-padding-top: 30px;
$msv-order-confirmation-pickup-date-timeslot-margin-bottom: 6px;
$msv-order-confirmation-pickup-date-timeslot-title-padding-bottom: 6px;
$ms-order-confirmation-deliverytype-grid-row: 40px;
$msv-order-confirmation-groups-padding-top-tablet: 40px;
$msv-order-confirmation-groups-padding-bottom-tablet: 40px;
$msv-order-confirmation-group-delivery-margin-top-t: 32px;

.ms-order-confirmation {
    width: $msv-width-full;
    margin: auto;
    position: relative;

    &__heading {
        @include font-heading-h1-l();
        color: var(--msv-font-primary-color);
        margin-bottom: $msv-details-heading-margin-bottom;
        margin-top: $msv-details-heading-margin-top;
    }

    .qrcode-screenreader-text {
        @include visually-hidden();
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;
        padding-top: $msv-details-order-information-padding-top;

        &-qr-code {
            align-items: center;
            background-color: $msv-details-order-information-qr-code-block-bg-color;
            display: flex;
            height: $msv-details-order-information-qr-code-block-height;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: $msv-width-full;
            }
        }

        &-channel-reference-id {
            @include font-body-regular-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            margin-top: $msv-details-order-information-sales-id-margin-bottom;
            color: $msv-gray-40;
        }

        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            @include font-body-regular-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                @include font-body-bold-m();
            }
        }

        &-created-date {
            @include font-heading-h3-l();
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $msv-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        display: none;
    }

    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: $msv-details-sale-lines-padding;

        &-shipping-items-label, &-picking-items-label, &-emailing-items-label {
            display: block;

            @include font-heading-h5-l();
        }

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        @include font-body-regular-m();
        border-bottom: 0.5px solid $msv-gray-50;
        display: flex;
        flex-wrap: wrap;
    }

    &__group {
        &-deliveryType-pickup, &-deliveryType-ship, &-deliveryType-email {
            flex: 0 0 $msv-details-order-information-full-width;
            display: flex;
            flex-direction: column;
            border-top: 0.5px solid $msv-gray-50;
            padding-top: $msv-order-confirmation-groups-padding-top;
            padding-bottom: $msv-order-confirmation-groups-padding-bottom;
        }

        &-delivery {
            padding-right: $msv-details-sale-line-spacing;

            &-heading {
                @include font-heading-h5-l();
                margin-right: $msv-details-text-margin-right;
                text-transform: capitalize;
            }

            &-total-items {
                @include font-heading-h2-l();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        margin: $msv-details-sale-line-margin;

        .msc-cart-line {
            display: flex;            

            &__product {
               margin-left:20px;
               &-short-description{
                   padding:5px 0px;
                   font-size: 14px;
                   display: block;
               }
            }
            &__quantity{
                margin-left:20px;
            }

            &__product-image {
                margin: 0;

                .msc_image {
                    border: 0.5px solid $msv-gray-50;
                    box-sizing: border-box;
                }
            }

            &__product-title {
                @include font-body-regular-m();
            }

            &__quantity {
                // display: none;

                .quantity-label {
                    @include font-body-regular-s();
                }

                .quantity-value {
                    color: $msv-gray-500;
                    display: inline;

                    @include font-body-regular-s();
                    margin-left: $msv-details-text-margin-right;
                }
            }

            &__product-price, &__product-savings {
                display: none;
            }

            // &__quantity.single-quantity {
            //     visibility: hidden;
            // }

            &-item-product-discount {
                display: none;
                color: var(--msv-success-color);
            }

            &__backorder {
                color:red;
                font-size:14px;
                padding-top:5px;
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button-light();
        }
    }

    &__order-summary {
        @include font-body-regular-m();
        padding: $msv-details-default-padding;
        width: 50%;

        &::after {
            clear: both;
        }

        &-heading {
            margin-bottom: $msv-details-order-summary-heading-margin-bottom;

            @include font-heading-h3-l();
            text-transform: capitalize;
            text-align: left;
            width: $msv-width-full;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            width: $msv-width-full;

            @include font-body-regular-m();
        }

        &-line-total-amount {
            border-top: 1px solid $msv-gray-50;
            padding-top: $msv-details-order-summary-line-total-amount-padding-bottom;
            width: $msv-width-full;

            @include font-heading-h5-l();

            .ms-order-details__order-summary-label {
                @include font-heading-h6-l();
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                @include font-heading-h6-l();
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        @include font-body-regular-m();
        padding: $msv-details-default-padding;
        display: none;

        &-title {
            @include font-heading-h6-l();
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        float: right;
        margin-top: $msv-details-payment-methods-margin-top;
        display: flex;
        flex-direction: column;
        width: $msv-width-quarter;

        &-title {
            @include font-heading-h5-l();
            text-transform: capitalize;
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
            margin-top: $msv-details-payment-methods-heading-margin-top;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-space;

            @include font-body-regular-m();
        }

        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: $msv-order-confirmation-payment-method-amount-margin-left;
        }
    }

    &__address {
        @include font-body-regular-m();
        margin-bottom: $msv-order-confirmation-address-margin-bottom;
        margin-top: $msv-order-confirmation-address-margin-top;

        &-header {
            display: none;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-county {
            display: none;
        }

        &-name {
            display: none;
            height:0;
            width:0;
        }

        &-phone {
            display: block;

            &-label {
                display: none;
            }

            &-icon {
                padding-right: $msv-order-confirmation-address-phone-padding-right;

                @include add-icon($msv-Phone);

                &::before {
                    font-size: $msv-font-size-ml;
                    vertical-align: middle;
                }
            }
        }
    }

    &__pickup-date-timeslot {
        padding-top: $msv-order-confirmation-pickup-date-timeslot-padding-top;
        margin-bottom: $msv-order-confirmation-pickup-date-timeslot-margin-bottom;

        &-title {
            @include font-heading-h6-l();
            padding-bottom: $msv-order-confirmation-pickup-date-timeslot-title-padding-bottom;
        }

        &-content {
            @include font-body-regular-m();
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @media screen and (min-width: $msv-container-min-width-m) {
        margin: $msv-main-container-margin;

        &__sales-lines {
            &-emailing-items-label {
                margin-top: auto;
            }
        }

        &__address {
            &-phone {
                margin-top: $msv-order-confirmation-address-phone-margin-top;
            }
        }

        &__groups {
            & > div:nth-child(2n) + div::before {
                content: "";
                display: block;
                width: 200%;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m - 1) {
        &__groups {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__group-deliveryType-pickup {
            padding-left: 0;
        }

        &__payment-methods {
            align-items: flex-start;
        }

        &__sales-lines {
            width: $msv-width-full;

            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary, &__payment-methods, &__help {
            float: none;
            padding-right: 0;
            width: $msv-width-full;
            position: relative;
            margin: $msv-details-default-margin;
        }

        &__help, &__order-summary {
            border-top: 0.5px solid $msv-gray-50;
        }

        &__order-summary {
            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: $msv-width-full;
            }
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: $msv-width-full;
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    @media screen and (max-width: $msv-breakpoint-l - 1) {
        .ms-order-confirmation {
            &__group-delivery {
                order: 3;
                margin-top: $msv-order-confirmation-group-delivery-margin-top-t;
            }

            &__groups > div {
                padding-top: $msv-order-confirmation-groups-padding-top-tablet;
                padding-bottom: $msv-order-confirmation-groups-padding-bottom-tablet;
            }

            &__sales-lines {
                order: 2;

                &-shipping-items-label,
                &-picking-items-label,
                &-emailing-items-label {
                    order: 1;
                }
            }

            &__sales-line {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-order-confirmation {
            &__group {
                &-deliveryType {
                    &-pickup,
                    &-ship,
                    &-email {
                        display: grid;
                        grid-template-columns: 3fr 1fr;
                        grid-template-rows: $ms-order-confirmation-deliverytype-grid-row;
                    }
                }

                &-delivery {
                    grid-row: 1/3;
                    grid-column: 2/3;
                }
            }

            &__sales-lines {
                grid-row: 2/3;
                grid-column: 1/2;

                &-shipping-items-label,
                &-picking-items-label {
                    grid-row: 1/2;
                    grid-column: 1/2;
                }
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        .ms-order-confirmation__payment-methods {
            &:last-child {
                float: left;
                width: 75%;
                padding-right: 20%;
            }
        }

        .ms-order-confirmation__order-summary-line-total-amount {
            margin-bottom: $msv-details-order-summary-line-total-amount-margin-bottom;
        }
    }

    @include image($msv-order-empty-image-width);

    .msc-empty_image {
        background-size: 100%;
    }
}

.ms-order-confirmation__sales-line .msc-cart-line{
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}
.ms-order-confirmation__sales-line .msc-cart-line__product-image{
    float: left;
    width:90px;
}

.ms-order-confirmation__sales-line .msc-cart-line__content{
    float: left;
    width: calc(100% - 100px);
}