$ms-accordion-drawer-button-color:rgba(44, 38, 45, 1);

.ms-accordion {
    &-header-section {
        margin: 0px;
        padding: 30px 0px;

        &-toggle-button-container {
            text-align: right;
            margin-bottom: 25px;
        }

        .ms-accordion_ExpandAll,
        .ms-accordion_CollapseAll {
            background-color: $msv-white;
            border: none;
        }
    }


    @media (min-width: $msv-breakpoint-m) {
        &-header-section {
            display: flex;
            margin: 5px 5px 20px 5px;

            &-container {
                flex-grow: 4;
            }

            &-toggle-button-container {
                margin-top: 0;
            }
        }
    }

    &-item-section {
        margin-bottom:30px;
        border-bottom: 0.5px solid $msv-gray-50;
        .drawer.ms-accordion-item__drawer {
            border-top: 0.5px solid $msv-gray-50;
            border-right: 0.5px solid $msv-gray-50;
            background-color: $msv-white;
        }

        .drawer__button.msc-btn.btn-block {
            width: 100%;
            height: 68px;
            display: flex;
            background-color: #f1f1f1;
            justify-content: space-between;
            border: none;
            outline: none;
            border-left: 5px solid var(--msv-promo-banner-bg);
            margin: 0px;

            &[aria-expanded="true"] {
                @include add-icon($msv-Remove, after);

                &::after {
                    color: $ms-accordion-drawer-button-color;
                    line-height: 20px;
                    float: right;
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }

            &[aria-expanded="false"] {
                @include add-icon($msv-Add, after);

                &::after {
                    color: $ms-accordion-drawer-button-color;
                    line-height: 20px;
                    float: right;
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }

            &:focus {
                border: 0.5px solid #{$msv-blue};
                border-left: 5px solid var(--msv-promo-banner-bg);
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }
        }

        .drawer__buttontext.__start {
            @include font-body-bold-s();
            float: left;
            margin-bottom: auto;
            margin-top: auto;
        }

        .ms-text-block {
            margin-bottom: 25px;
            margin-top: 0;
        }
    }

    &-item {
        &__drawer {
            .drawer__button {
                background: var(--msv-accordion-drawer-button-background);
                display: flex;
                justify-content: space-between;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                margin-top: 5px;
                cursor: pointer;
                padding: 10px 20px;
                border-left: 5px solid #ED0000;

                &[aria-expanded="true"] {
                    @include add-icon($msv-ChevronUp, after);
                    padding-right: 20px;

                    &::after {
                        color: #ED0000;
                        line-height: 24px;
                    }
                }

                &[aria-expanded="false"] {
                    @include add-icon($msv-ChevronDown, after);
                    padding-right: 20px;

                    &::after {
                        color: #ED0000;
                        line-height: 24px;
                    }
                }
            }
        }

        &-content {
            padding: 20px;
            border: 1px solid #d1d1d1;
            border-top: 0px;

            .ms-text-block {
                margin-top: 12px;
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                font-size: var(--msv-accordion-item-content-block-font-size) !important;
                color: var(--msv-accordion-item-content-block-color);
                background: var(--msv-accordion-item-content-block-background);
            }
        }
    }
}
