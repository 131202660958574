$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 665px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-lg: 1366px;
$msv-breakpoint-xl: 1440px;

$msv-container-min-width-m: 769px;

$msv-width-full: 100%;
$msv-width-half: 50%;
$msv-width-threequarter: 75%;
$msv-width-quarter: 25%;
$msv-height-full: 100%;
