$msv-store-selector-phone-section-padding-top: 13px;
$msv-store-selector-location-line-shop-address-padding-top: 2px;
$msv-store-selector-location-line-shop-address-padding-right: 15px;
$msv-store-selector-button-border-radius: 3px;
$msv-store-selector-input-border-radius: 3px;
$msv-store-selector-input-padding: 16px;
$msv-store-selector-input-height: 18px;
$msv-store-selector-input-width: 87%;
$msv-store-selector-button-height: 52px;
$msv-store-selector-button-width: 52px;
$msv-store-selector-button-margin-left: 4px;
$msv-store-selector-search-form-padding: 0 0 20px 0;
$msv-store-selector-search-form-padding-mobile: 0 16px 10px 16px;
$msv-store-selector-location-line-item-margin-top: 25px;
$msv-breakpoint-modal-m: 450px;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-locations-max-height:400px;
$pickup-option-list-margin: 5px;
$pickup-option-list-item-padding-left: 16px;
$pickup-option-list-item-padding-bottom: 10px;
$pickup-option-list-item-padding-top: 7px;
$pickup-option-list-item-padding-right: 5px;
$pickup-option-height: 52px;
$pickup-option-list-margin: 0;
$msv-store-location-pickup-option-line-height: 24px;
$msv-store-selector-input-box-sizing: border-box;
$msv-store-selector-input-padding-margin: 35px;
$msv-store-selector-location-line-pickup-list-toggle-padding-left: 12px;
$msv-store-location-pickup-option-filter-padding: 10px;

//style presets
:root {
    --msv-store-select-form-bg: #{$msv-gray-100};
    --msv-store-select-form-input-color: #{$msv-text-color};
    --msv-store-select-form-input-border: #{$msv-gray-50};

    //body title
    --msv-store-select-title-font-color: var(--msv-accent-brand-color);

    //body text
    --msv-store-select-text-font-size: var(--msv-body-font-size-m);
    --msv-store-select-text-font-color: var(--msv-font-primary-color);
    --msv-store-select-distance-color: var(--msv-accent-brand-color);

    //Link
    --msv-store-select-link-size: var(--msv-body-font-size-s);
    --msv-store-select-link-color: var(--msv-font-primary-color);
    --msv-store-select-divider-color: #{$msv-gray-300};

    // pickup option list icon color
    --ms-pickup-option-icon-color: #{$msv-blue};
    --ms-pickup-option-error-color: #{$msv-red};
}

.ms-store-select {
    .msc-modal__content {
        padding: 34px 0 0 0;
    }

    &__found-locations {
        &__text {
            @include font-body-regular-m();
            color: var(--msv-store-select-text-font-color);
            margin-bottom: 16px;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 0 16px;
                margin-bottom: 12px;
            }
        }

        &__count,
        &__radius {
            font-weight: $msv-font-weight-700;
        }
    }

    &__no-locations {
        @include font-body-regular-m();
        color: var(--msv-store-select-text-font-color);

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 0 16px;
        }
    }

    &__modal-body {
        min-height: 400px;
        padding: 0 40px;
        margin-bottom: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $msv-breakpoint-m) {
            flex: 1 1 auto;
            max-height: 100%;
        }
    }

    &__modal-header, .msc-modal__header {
        padding: 0 40px;

        @media screen and (max-width: $msv-breakpoint-m) {
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            height: auto !important;
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            padding: 12px 16px 25px 16px !important;
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            border: none !important;
        }

        .msc-modal__title {
            @include font-heading-h3-l();
        }

        .msc-modal__close-button {
            @media screen and (max-width: $msv-breakpoint-m) {
                right: 10px;
            }
        }
    }

    &.msc-modal__dialog.msc-modal-input-required .msc-modal__content .msc-modal__header .msc-modal__title {
        @media screen and (max-width: $msv-breakpoint-m) {
            text-align: left;
        }
    }

    &.msc-modal__dialog.ms-store-select.msc-modal-input-required .ms-store-select__modal-body.msc-modal__body {
        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 0;
        }
    }

    &__modal-footer {
        padding: 13px 40px;
        border-top: 0.5px solid $msv-gray-50;
        box-sizing: border-box;

        @media screen and (max-width: $msv-breakpoint-m) {
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            padding: 13px 16px 17px 16px !important;
        }
    }

    &__search {
        display: block;
        padding: $msv-store-selector-search-form-padding;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: $msv-store-selector-search-form-padding-mobile;
        }

        &-form {
            flex: auto;
            display: flex;
            margin-bottom: $msv-store-selector-search-form-margin-bottom;
            position: relative;
            align-items: center;

            .MicrosoftMap {
                .as_container_search {
                    left: 0;
                    top: 61px;
                    max-width: 360px;
                    // width: 100%;

                    .asOuterContainer {
                        border-width: 1px;
                    }
                }

                .as_container {
                    .suggestLink {
                        color: var(--msv-store-select-link-color);
                        font-size: var(--msv-store-select-link-size);
                    }

                    .bingLogoContainer {
                        /* stylelint-disable-next-line declaration-no-important -- To override inline styling. */
                        display: block !important;
                        border-top: 0.5px solid var(--msv-store-select-form-input-border);
                        height: 56px;
                    }

                    .bingLogoLight {
                        position: relative;
                        margin: auto;
                        top: 16px;
                    }
                }
            }
        }

        &-see-all-stores {
            color: var(--msv-store-select-link-color);
            text-decoration: underline;
            display: none;
            @include font-body-regular-s();
            cursor: pointer;
            width: fit-content;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        &-header {
            display: flex;
            flex-direction: row-reverse;

            &-pickup {
                z-index: 1015;
                align-self: flex-start;
                align-items: self-end;
                position: relative;

                &-menu {
                    @include form-input-box();
                    @include add-icon($msv-ChevronDown, after);
                    border: none;
                    padding: $msv-store-location-pickup-option-filter-padding;
                    padding-right: 0;
                    height: auto;
                    display: none;

                    &::after {
                        font-size: 20px;
                        vertical-align: bottom;
                        padding-left: $msv-store-location-pickup-option-filter-padding;
                    }

                    &:focus {
                        outline: none;
                        filter: none;
                    }

                    &:active {
                        box-shadow: none;
                    }
                }

                &-list {
                    display: block;
                    position: absolute;
                    width: 100%;

                    &-item {
                        background-color: $msv-white;

                        &:hover {
                            background-color: $msv-white;
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }

                    &-link {
                        border: none;
                        line-height: $msv-store-location-pickup-option-line-height;
                        padding: $msv-store-location-pickup-option-filter-padding;
                        text-align: center;
                        width: 100%;
                        outline: none;

                        @include font-body-regular-s();

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &-heading {
                display: none;
            }
        }

        &-input, .msc-form-control {
            @include form-input-box();
            -webkit-appearance: none;
            padding: $msv-store-selector-input-padding;
            height: $msv-store-selector-input-height;
            width: calc(100% - (#{$msv-store-selector-button-width} + #{$msv-store-selector-button-margin-left} + #{$msv-store-selector-input-padding-margin}));

            @include font-body-regular-s();
        }

        &-button {
            @include primary-button-light();

            border-radius: $msv-store-selector-button-border-radius;
            height: $msv-store-selector-button-height;
            min-width: unset;
            order: 2;
            font-size: unset;
            padding: unset;
            width: $msv-store-selector-button-width;
            margin-left: $msv-store-selector-button-margin-left;

            &:hover {
                cursor: pointer;
            }

            &::before {
                @include msv-icon();
                content: $msv-Search;
                width: $msv-store-selector-button-width;
                text-align: center;
            }

            &:focus::before {
                @include msv-icon();
                content: $msv-Search;
                width: $msv-store-selector-button-width;
                text-align: center;
                position: static;
                border-radius: unset;
                padding: 14px 0;
                margin: 3px;
            }
        }

        .search-btn-disabled {
            background-color: $msv-gray-300;
        }
    }

    &__toggle-view {
        display: none;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    &__store-hours-details {
        @include font-body-regular-s();
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 13px;

        .ms-store-select__store-hours-day {
            flex: 0 0 25%;

            abbr {
                text-decoration: none;
            }
        }

        .ms-store-select__store-hours-time {
            flex: 0 0 60%;
        }
    }

    &__locations {
        max-height: $msv-store-locations-max-height;
        overflow-y: scroll;
        padding: 0 10px 32px 0;
        margin-right: 5px;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 0 8px 0 16px;
            margin-right: 4px;
            max-height: 100%;
        }
    }

    @media (forced-colors: active) and (prefers-color-scheme: dark) { //to change the scrollbar color in store locator when user set high contrast theme
        &__locations::-webkit-scrollbar-thumb {
            background-color: $msv-white;
            outline: 1px solid $msv-white;
            border-radius: 10px;
        }
    }

    @media (forced-colors: active) and (prefers-color-scheme: light) {
        //to change the scrollbar color in store locator when user set high contrast theme
        &__locations::-webkit-scrollbar-thumb {
            background-color: $msv-black;
            outline: 1px solid $msv-black;
            border-radius: 10px;
        }
    }

    &__location {
        padding: 20px 50px;
        border: 0.5px solid var(--msv-store-select-divider-color);
        border-radius: 10px;
        margin-bottom: 10px;
        position: relative;

        &-wrapper {
            position: relative;
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $msv-gray-210;
            display: flex;
            flex-direction: column;

            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 20px;
                padding-bottom: 20px;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 14px 0;
        }

        @include add-icon($msv-store, before);

        &::after {
            width: 40px;
            height: 40px;
            content: "";
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
            border: 0.5px solid $msv-gray-50;
            border-left: none;
            top: 15px;
        }

        &::before {
            position: absolute;
            top: 27px;
            left: 10px;
            color: $msv-gray-20;
            z-index: 1;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-line-item {
            color: var(--msv-store-select-text-font-color);
            font-size: var(--msv-store-select-text-font-size);
            position: relative;

            &-set-as-preferred-store {
                border: none;
                color: var(--msv-store-select-link-color);
                padding: 16px 18px 16px 50px;
                outline: none;
                background-color: transparent;
                cursor: pointer;
                display: flex;
                align-items: center;
                position: absolute;
                top: -16px;
                right: 0;

                @include font-body-regular-s();

                @media screen and (max-width: $msv-breakpoint-m) {
                    position: relative;
                    border-top: 1px solid var(--msv-store-select-divider-color);
                    width: 100%;
                    padding: 13px 50px;
                    margin-top: 14px;
                    justify-content: center;
                    flex-direction: row-reverse;
                    left: auto;
                    top: 0;
                    right: auto;
                }

                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    border: 1px solid $msv-blue;
                    border-radius: 100px;
                }

                &::after {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    border: 0.5px solid $msv-gray-50;
                    border-radius: 3px;
                    box-sizing: border-box;
                    padding: 2px;
                    content: "";
                    position: absolute;
                    left: 16px;
                    top: 14px;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        left: auto;
                        position: relative;
                        margin-right: 10px;
                        top: 0;
                    }
                }
            }

            &-preferred-store {
                display: block;
                margin-top: 0;
                position: absolute;
                top: -16px;
                right: 37px;
                padding: 16px 18px 16px 50px;
                align-items: center;

                @include font-body-regular-s();
                cursor: pointer;
                border: 1px solid transparent;
                outline: none;
                background-color: transparent;

                @media screen and (max-width: $msv-breakpoint-m) {
                    position: relative;
                    border-top: 1px solid var(--msv-store-select-divider-color);
                    width: 100%;
                    padding: 13px 50px;
                    margin-top: 14px;
                    justify-content: center;
                    flex-direction: row-reverse;
                    display: flex;
                    right: auto;
                    top: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    border: 1px solid $msv-blue;
                    border-radius: 100px;
                }

                &::before {
                    position: absolute;
                    left: 23px;
                    top: 15px;
                    border: solid $msv-black;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    width: 10px;
                    height: 18px;
                    content: "";

                    @media screen and (max-width: $msv-breakpoint-m) {
                        left: -125px;
                        top: -2px;
                        position: relative;
                    }
                }

                &::after {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    border: 0.5px solid $msv-gray-50;
                    border-radius: 3px;
                    box-sizing: border-box;
                    padding: 2px;
                    content: "";
                    position: absolute;
                    left: 16px;
                    top: 14px;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        left: auto;
                        top: auto;
                        position: relative;
                        margin-right: 10px;
                    }
                }
            }
        }

        &-line-header {
            margin-bottom: 13px;

            @include font-body-bold-s();
        }

        &-line-stock-status {
            margin-bottom: 13px;

            @include font-body-regular-s();
        }

        &-line-item-header {
            margin-bottom: 28px;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-bottom: 25px;
                padding: 0 50px;
            }
        }

        &-line-item-store-name {
            color: var(--msv-store-select-title-font-color);

            @include font-heading-h6-l();
        }

        &-line-item-store-index {
            color: var(--msv-store-select-title-font-color);

            @include font-heading-h6-l();
            margin-right: 5px;
            display: none;

            &::after {
                content: ".";
            }
        }

        &-line-item-store-distance {
            @include font-body-regular-xs();
            color: var(--msv-store-select-distance-color);
            padding-left: 12px;
        }

        &-line-store-distance {
            color: $msv-gray-500;
        }

        &-line-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-line-shop-address {
            &-section {
                display: flex;
            }

            &-glyph-icon {
                padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                @include add-icon($msv-Shop-Address);
            }

            &-text {
                @include font-body-regular-s();
                white-space: pre-wrap;
            }
        }

        &-line-shop-phone {
            &-section {
                display: flex;
                padding-top: $msv-store-selector-phone-section-padding-top;
            }

            &-icon {
                padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                @include add-icon($msv-Phone, before);
            }

            &-text {
                @include font-body-regular-s();
            }
        }

        &-line-select-store {
            @include primary-button-light();
            align-self: flex-end;
        }

        &-line-pickup {
            z-index: 1005;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            &-menu {
                @include primary-button-light();

                cursor: pointer;
                align-self: flex-start;
                margin-left: auto;
            }

            &-menu:active:focus:hover {
                border: none;
                outline: none;
            }

            &-label {
                @include font-body-bold-s();
                flex: 0 0 100%;
                margin-bottom: 7px;
            }

            &-list-toggle {
                text-align: left;

                @include form-input-box();
                @include add-icon($msv-ChevronDown, after);
                padding-left: $msv-store-selector-location-line-pickup-list-toggle-padding-left;

                &::after {
                    position: absolute;
                    right: 225px;
                    font-size: 20px;

                    @media (max-width: $msv-breakpoint-m) {
                        right: 18px;
                    }

                    @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                        right: 255px;
                    }
                }

                &:focus {
                    outline: none;

                    &::after {
                        right: 11px;
                    }
                }
            }

            &[data-selected=true] {
                .ms-store-select__location-line-pickup-label {
                    @include add-icon($msv-Checkbox-Circle-Checked, after);

                    &::after {
                        position: relative;
                        top: 48px;
                        z-index: 10;
                        left: -85px;
                        font-size: 20px;
                        color: var(--ms-pickup-option-icon-color);
                    }
                }

                .ms-store-select__location-line-pickup-list-toggle {
                    padding-left: 45px;

                    &.preselected {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &-list {
                display: none;

                &.show {
                    display: block;
                    border: 0.5px solid $msv-gray-50;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &-container {
                    display: flex;
                    flex-direction: column;
                    width: 72%;

                    @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                        width: 68%;
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                @include font-body-regular-s();

                &-item {
                    background-color: var(--msv-store-select-form-bg);

                    &-button {
                        border: none;
                        padding-left: $pickup-option-list-item-padding-left;
                        background-color: $msv-white;
                        width: 100%;
                        text-align: left;
                        height: $pickup-option-height;
                    }

                    &:hover {
                        background-color: $msv-white-20;
                        cursor: pointer;
                    }

                    &__text {
                        width: 100%;

                        &:hover::before {
                            outline: none;
                        }
                    }
                }

                &-link {
                    border: none;
                    line-height: $msv-store-location-pickup-option-line-height;
                    padding-bottom: $pickup-option-list-item-padding-bottom;
                    padding-left: $pickup-option-list-item-padding-left;
                    padding-top: $pickup-option-list-item-padding-top;
                    padding-right: $pickup-option-list-item-padding-right;
                    text-align: center;
                    width: 100%;
                    outline: none;

                    @include font-body-regular-s();
                }

                &-error {
                    display: none;

                    @include font-body-regular-xs();
                }
            }

            &[data-error=true] {
                .ms-store-select__location-line-pickup-list-error {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    color: var(--ms-pickup-option-error-color);

                    @include add-icon($msv-times-circle, before);

                    &::before {
                        margin-right: 10px;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &__terms-link {
        @include font-body-regular-s();
        color: var(--msv-store-select-link-color);
        text-decoration: underline;
        border: none;
    }

    &.msc-modal__dialog select {
        /* stylelint-disable-next-line declaration-no-important -- To override important styling for modal select element. */
        background-color: transparent !important;
    }
}

@media screen and (min-width: $msv-breakpoint-m + 1) {
    .ms-store-select__location {
        &-line-item-content {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-line-item-contact-info {
            width: 33%;
            margin-right: 15px;
        }

        &-line-item-store-hours {
            width: 38%;
            margin-right: 15px;
            flex: auto;
        }

        &-line-item-store-availability {
            width: 24%;
        }

        &-line-stock-status {
            @include font-body-regular-s();
            position: relative;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select {
        &__toggle-view {
            display: block;
            background-color: transparent;
            float: right;
            border: none;
            outline: none;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select {
        &__location {
            padding: 24px 0 0;

            &-line-select-store {
                width: 100%;
            }

            &-line-item {
                &-store-hours,
                &-store-availability,
                &-contact-info {
                    margin-top: $msv-store-selector-location-line-item-margin-top;
                    padding: 0 30px 0 50px;
                }

                &-store-availability {
                    padding-bottom: 25px;
                }
            }

            &-line-pickup {
                flex-direction: column;
                align-items: stretch;
                padding-top: 9px;

                &-list {
                    margin-bottom: 13px;
                    width: 100%;
                }

                &-menu {
                    margin-left: 0;
                    width: 100%;
                }
            }
        }
    }
}
