/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: $msv-primary-font-family;
    background: var(--msv-body-bg-color);
    color: $msv-text-color;
    font-size: $msv-text-size;

    @include lineHeightFromFontSize($msv-text-size);
    min-height: 100vh;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $msv-primary-font-family;
}

header {
}

/* Remove list styles on ul, ol elements with a class attribute */

/* Remove default padding */
ol[class],
ul[class] {
    padding: 0;
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a:hover {
    text-decoration: none;
    color: var(--msv-accent-brand-alt-color);
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
a {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--msv-font-primary-color);
    display: inline-flex;
    text-decoration: none;
}
button{ cursor:pointer;}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
    * {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        animation-duration: 0.01ms !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        animation-iteration-count: 1 !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        transition-duration: 0.01ms !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        scroll-behavior: auto !important;
    }
}

.msc-confirm-added-to-cart__dialog__content {
    justify-content: center;
}
.msc-confirm-added-to-cart__dialog__body p {
    flex-grow: 1;
    text-align: center;
    margin: 15px 0px;
}
.msc-confirm-added-to-cart__dialog__body p label {
    display: block;
    font-size: 20px;
    font-weight: 700;
}
.msc-confirm-added-to-cart__dialog__body p .ship-icon {
                    @include add-icon($msv-DeliveryTruck, before);
                    @include font-heading-h5-l();
                    font-size: 150px;

                }
				
.msc-confirm-added-to-cart__dialog__body p .store-icon {
                   @include add-icon($msv-store, before);
                    @include font-heading-h5-l();

                   font-size: 150px;
                }	
.msc-confirm-added-to-cart__dialog__footer{
                    text-align: right;
                }
.msc-confirm-added-to-cart__dialog__footer button {
                    @include primary-button-light();
                    text-transform: uppercase;
                    height: 100%;
                }
                .msc-confirm-added-to-cart__dialog__body p:first-child {
                    border-right: 4px solid #000;
                }
                @media (max-width: $msv-breakpoint-s) {
                    .msc-confirm-added-to-cart__dialog__body p .ship-icon,
                    .msc-confirm-added-to-cart__dialog__body p .store-icon{font-size: 80px;}
                }
				
				 
				 